import React from "react"
import { Link } from "gatsby"
import { BrandFacebook, BrandLinkedin } from "tabler-icons-react"
export default function Navigation() {
  return (
    <>
      <nav className="z-10">
        <ul className="md:inline-flex md:space-x-8 lg:space-x-12 white-text">
          <li className="list-none">
            <Link
              to="/"
              title="Home"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              Home
            </Link>
          </li>
          <li className="list-none">
            <Link
              to="/about"
              title="About Us"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              About
            </Link>
          </li>
          <li className="list-none">
            <Link
              to="/products"
              title="Products"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              Products
            </Link>
          </li>
          <li className="list-none">
            <Link
              to="/rates"
              title="Rates"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              Rates
            </Link>
          </li>
          <li className="list-none dropdown">
            <Link
              to="/brokers"
              title="Brokers"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              Brokers
            </Link>
            <ul className="dropdown bg-gray-900">
              <li>
                <a
                  href="https://brokerportal.marathonmortgage.ca/mmc-portal/login"
                  title="Broker Portal"
                  className="no-underline hover:orange transition duration-300 ease-in-out"
                  target="_blank"
                  rel="noreferrer"
                >
                  Broker Portal
                </a>
              </li>
            </ul>
          </li>
          <li className="list-none">
            <Link
              to="/borrowers"
              title="Borrowers"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              Borrowers
            </Link>
          </li>
          <li className="list-none">
            <Link
              to="/calculators"
              title="Calculators"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              Calculators
            </Link>
          </li>
          <li className="list-none">
            <Link
              to="/careers"
              title="Careers"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              Careers
            </Link>
          </li>
          <li className="list-none">
            <Link
              to="/contact"
              title="Contact"
              className="no-underline hover:orange transition duration-300 ease-in-out"
              activeClassName="active"
            >
              Contact
            </Link>
          </li>
          <li className="list-none">
            <a
              href="https://bit.ly/3gRKM1E"
              title="facebook"
              className="no-underline hover:orange transition duration-300 ease-in-out"
            >
              <BrandFacebook strokeWidth={1} />
            </a>
          </li>
          <li className="list-none">
            <a
              href="https://bit.ly/3Fg9kuA"
              title="facebook"
              className="no-underline hover:orange transition duration-300 ease-in-out"
            >
              <BrandLinkedin strokeWidth={1} />
            </a>
          </li>
        </ul>
      </nav>
    </>
  )
}
