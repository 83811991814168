import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function SimpleAccordion() {

    return (
    <div className="disclaimer">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
            <p><small>Legal Disclaimer</small></p>
        </AccordionSummary>
        <AccordionDetails>
            <p><small>Information and interactive calculators are made available to you as self-help tools for your independent use and are not intended to provide investment advice.</small></p>
            <p><small>We cannot and do not guarantee their applicability or accuracy in regards to your individual circumstances.</small></p>
            <p><small>All examples are hypothetical and are for illustrative purposes. We encourage you to seek personalized advice from qualified professionals regarding all personal finance issues.</small></p>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}