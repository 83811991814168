import React, { useState } from "react"
import { Container, Grid, Paper } from "@material-ui/core"
import styled from "styled-components"
import numeral from "numeral"
import Disclaimer from "./disclaimer"

const Error = styled.p`
  display: flex;
  width: 250px;
  padding: 0.5rem 0;
  margin: 0;
  font-weight: bold;
`
const SubmitButton = styled.button`
  -webkit-appearance: none;
  background: transparent;
  transition: 0.3s ease;
  border: 2px solid #2c5282;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
`

const MaximumMortgage = () => {
  const [familyIncome, setFamilyIncome] = useState("")
  const [propertyTaxes, setPropertyTaxes] = useState("")
  const [monthlyFees, setMonthlyFees] = useState("")
  const [monthlyPayments, setMonthlyPayments] = useState("")
  const [secondaryFinancing, setSecondaryFinancing] = useState("")
  const [interestRate, setInterestRate] = useState("")
  const [payment, setPayment] = useState("")
  const [mortgage, setMortgage] = useState("")

  const submitCalculation = async e => {
    e.preventDefault()

    // Validate Fields
    const validatedFamilyIncome = await validateField(
      familyIncome,
      setFamilyIncome
    )
    const validatedPropertyTaxes = await validateField(
      propertyTaxes,
      setPropertyTaxes
    )
    const validatedMonthlyFees = await validateField(
      monthlyFees,
      setMonthlyFees
    )
    const validatedMonthlyPayments = await validateField(
      monthlyPayments,
      setMonthlyPayments
    )
    const validatedSecondaryFinancing = await validateField(
      secondaryFinancing,
      setSecondaryFinancing
    )
    const validatedInterestRate = await validateField(
      interestRate,
      setInterestRate
    )

    // If valid, create all values

    if (
      validatedFamilyIncome &&
      validatedPropertyTaxes &&
      validatedMonthlyFees &&
      validatedMonthlyPayments &&
      validatedSecondaryFinancing &&
      validatedInterestRate
    ) {
      calculateValues()
    }
  }

  const validateField = (field, setValue) => {
    let int = parseFloat(field)

    if (field === "") {
      setValue({ ...field.values, error: "Please enter a value" })
      return false
    } else if (isNaN(int)) {
      setValue({ ...field.values, error: "Please enter a number" })
      return false
    } else {
      setValue(int)
      return true
    }
  }

  const calculateValues = () => {
    let RATE = interestRate / 100
    let income = familyIncome
    let tax = propertyTaxes
    let heat = monthlyFees * 12
    let debt = monthlyPayments * 12
    let second = secondaryFinancing * 12
    let compound = 2 / 12
    let monTime = 25 * 12
    let yrRate = RATE / 2
    let rdefine = Math.pow(1.0 + yrRate, compound) - 1.0
    let purchcompound = Math.pow(1.0 + rdefine, monTime)

    let maxgdsr = 0.32
    let maxtdsr = 0.42

    let GDSPAY = maxgdsr * income - tax - heat - second
    let TDSPAY = maxtdsr * income - tax - heat - second - debt

    let TotalPayment = GDSPAY < TDSPAY ? GDSPAY / 12 : TDSPAY / 12
    let TotalMortgage =
      (0 + (TotalPayment * (purchcompound - 1.0)) / rdefine) / purchcompound

    setPayment(TotalPayment)
    setMortgage(TotalMortgage)
  }

  return (
    <Container>
      <Paper elevation={3}>
        <form>
          <Grid container className="mortgage-calculator">
            <Grid item md={7} sm={12} className="calc-fields">
              <div className="input-field">
                <label htmlFor="familyIncome">Family Income:</label>
                <Error>{familyIncome.error}</Error>
                <input
                  id="familyIncome"
                  type="number"
                  onChange={e => setFamilyIncome(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="propertyTaxes">
                  Annual Property Taxes: (estimated)
                </label>
                <Error>{propertyTaxes.error}</Error>
                <input
                  id="propertyTaxes"
                  type="number"
                  onChange={e => setPropertyTaxes(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="monthlyFees">
                  Monthly Heating Costs/Condo Fees: (estimate)
                </label>
                <Error>{monthlyFees.error}</Error>
                <input
                  type="number"
                  id="monthlyFees"
                  onChange={e => setMonthlyFees(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="monthlyPayments">
                  Minimum Monthly Payments for Loans/Credit Cards:
                </label>
                <Error>{monthlyPayments.error}</Error>
                <input
                  id="monthlyPayments"
                  type="number"
                  onChange={e => setMonthlyPayments(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="secondaryFinancing">
                  Monthly Secondary Financing Payment:
                </label>
                <Error>{secondaryFinancing.error}</Error>
                <input
                  id="secondaryFinancing"
                  type="number"
                  onChange={e => setSecondaryFinancing(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="interestRate">Interest Rate:</label>
                <Error>{interestRate.error}</Error>
                <input
                  id="interestRate"
                  type="number"
                  onChange={e => setInterestRate(e.target.value)}
                />
              </div>
              <div className="blue-button">
                <SubmitButton onClick={e => submitCalculation(e)}>
                  <span>calculate</span>
                </SubmitButton>
              </div>
            </Grid>
            <Grid item md={5} xs={12} className="calc-totals">
              <div className="totals">
                <h3 className="white-text">
                  {numeral(mortgage).format("$0,0.00")}
                </h3>
                <h6 className="l-orange-text">Maximum Mortgage</h6>
              </div>
              <div className="totals">
                <h3 className="white-text">
                  {numeral(payment).format("$0,0.00")}
                </h3>
                <h6 className="l-orange-text">Monthly Payment</h6>
              </div>
              <div className="totals white-text">
                <Disclaimer />
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}

export default MaximumMortgage
