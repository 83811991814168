import React from 'react'
import MortgageCalclator from './mortgageCalculator'

export default function FooterCalc() {
    return (
        <div className="footer-calc">
            <MortgageCalclator />
        </div>
    )
}
