import React from "react"
import { Link } from "gatsby"
import { Container, Grid, Hidden } from "@material-ui/core"

import Logo from "../../images/logo.svg"
import Navigation from "./navigation/navigation"
import MobileNavigation from "../theme/navigation/mobile-navigation"
// import Logo from "../../images/svg/logo.svg"
import { Checkbox } from "tabler-icons-react"

export default function Header() {
  return (
    <>
      {/* <div className="header-top text-center bg-blue-900 py-8">
      <p className="white-text">Ask about our Covid-19 response &amp; how we are looking out for you.  <Link to="/">Learn more</Link></p>
    </div> */}
      <header className="white-bg bg-gray-900">
        <Container>
          <Grid item container className="flex items-center logo">
            <Grid item xs={7} md={6}>
              <Link to="/">
                <img src={Logo} alt="Website Title" />
              </Link>
            </Grid>
            <Grid item xs={5} md={6} className="flex items-center justify-end">
              <Hidden smDown>
                <Navigation />
              </Hidden>
              <Hidden mdUp>
                <MobileNavigation />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </header>
      <p className="m-0 bg-orange absolute z-50 hidden md:flex quick-link">
        <a
          href="https://mtgapp.scarlettnetwork.com/Marathon_Mortgage/home"
          target="_blank"
          rel="noreferrer"
          className="flex py-5 px-10 place-items-center no-underline"
        >
          <Checkbox size={42} strokeWidth={2} color="#2E3748" />
          <span className="text-white ml-10">
            Apply for a mortgage online, <br />
            safely &amp; securely.
          </span>
        </a>
      </p>
    </>
  )
}
