import React from "react"
import { Link } from "gatsby"
import { Container, Grid, Hidden } from "@material-ui/core"
import FooterCalc from "../calculators/footerCalc"

import FooterIcon from "../../images/icon.svg"
import FooterBadge from "../../images/mortgage-employer-badge-footer.png"

export default function Footer() {
  return (
    <>
      <footer className="bg-gray-900">
        <Container className="py-40">
          <Grid container className="footer-items">
            <Grid item md={7} sm={12} className="flex flex-wrap white-text">
              <div className="col-1">
                <Link to="/">
                  <img src={FooterIcon} alt="MARATHON MORTGAGE CORP." />
                </Link>
              </div>
              <div className="col-2">
                <ul className="list-none">
                  <li className="pt-8">
                    <Link
                      to="/"
                      activeClassName="active"
                      className="hover:orange"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="pt-8">
                    <Link
                      to="/about"
                      activeClassName="active"
                      className="hover:orange"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="pt-8">
                    <Link
                      to="/products"
                      activeClassName="active"
                      className="hover:orange"
                    >
                      Products
                    </Link>
                  </li>
                  <li className="pt-8">
                    <Link
                      to="/rates"
                      activeClassName="active"
                      className="hover:orange"
                    >
                      Rates
                    </Link>
                  </li>
                  <img
                    src={FooterBadge}
                    alt="Marathon Mortgage - 5 Star Mortgage Employer Badge"
                    className="mt-8 md:mt-10"
                  />
                </ul>
              </div>
              <div className="col-3">
                <ul className="list-none">
                  <li className="pt-8">
                    <Link
                      to="/brokers"
                      activeClassName="active"
                      className="hover:orange"
                    >
                      Brokers
                    </Link>
                  </li>
                  <li className="pt-8">
                    <Link
                      to="/borrowers"
                      activeClassName="active"
                      className="hover:orange"
                    >
                      Borrowers
                    </Link>
                  </li>
                  <li className="pt-8">
                    <Link
                      to="/calculators"
                      activeClassName="active"
                      className="hover:orange"
                    >
                      Mortgage Calculators
                    </Link>
                  </li>
                  <li className="pt-8">
                    <Link
                      to="/contact"
                      activeClassName="active"
                      className="hover:orange"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item md={5} sm={12}>
              <Hidden smDown>
                <FooterCalc />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
        <div className="footer-bottom bg-orange py-8">
          <Container>
            <p className="m-0 footer-text">
              &copy; {new Date().getFullYear()} | ALL RIGHTS RESERVED | MARATHON
              MORTGAGE CORP. | <Link to="/privacy">PRIVACY POLICY</Link>
            </p>
          </Container>
        </div>
      </footer>
    </>
  )
}
